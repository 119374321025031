import React, { useEffect, useState } from "react";

import useShowAlert from "../utils/AlertUtiles";
import useErrorHandle from "../hooks/ErrorHandling";
import checkNetworkStatus from "../components/common/networkStatus/CheckNetworkStatus";
import { listShopOrder, ViewShopOrder } from "../services/orderServices";
import WarningErrorHandling from "../hooks/WarningErrorHandling";
import ViewOrders from "../components/specific/orders/ViewOrders";
import OrderTable from "../components/specific/orders/OrderTable";


function Orders({authToken}) {
  const [loading, setLoading] = useState(false);
  const [isOrders, setOrders] = useState(false);

  const showAlert = useShowAlert();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const handleError = useErrorHandle();

  const handleView = async (order) => {
    checkNetworkStatus();
   
    try {
      const payload = {
        shopid: order.shopid,
        orderid: order._id,
      };

      const data = await ViewShopOrder(payload, handleError,authToken);
      if (data) {
        setSelectedOrder(data);
      }
    } catch (error) {
      const statusCode = error?.response?.status || error?.statusCode;

      if (statusCode !== 404) {
        WarningErrorHandling(error, showAlert);
      } else if (statusCode === 404) {
        setSelectedOrder([]);
      }
    } 
  };


  const handleClose = () => {
    setSelectedOrder(null);
  };

  const fetchOrders = async (shopid = "", status = "") => {
    checkNetworkStatus();
    setLoading(true);
    try {
      const payload = {
        shopid: shopid,
        status: status,
      };

      const data = await listShopOrder(payload, handleError,authToken);
      if (data) {
        const filteredData = data.filter(order => order.shopid !== null);
        setOrders(filteredData);
      }
    } catch (error) {
      const statusCode = error?.response?.status || error?.statusCode;

      if (statusCode !== 404) {
        WarningErrorHandling(error, showAlert);
      } else if (statusCode === 404) {
        setOrders([]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders("", "CREATED");
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <OrderTable data={isOrders}  onView={handleView} loading={loading} listData={fetchOrders} />
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
         
          <ViewOrders
            order={selectedOrder}
            onClose={handleClose}
            setOrders={setOrders}
            authToken={authToken}
          />
    
        </div>
      </div>
    </div>
  );
}

export default Orders;
