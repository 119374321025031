import React, { useEffect, useState } from "react";
import "../style/shop.css";
import SuccessMsg from "../components/common/messages/SuccessMessage";
import useErrorHandle from "../hooks/ErrorHandling";
import useShowAlert from "../utils/AlertUtiles";
import checkNetworkStatus from "../components/common/networkStatus/CheckNetworkStatus";
import { removeData, setData } from "../utils/CookieUtiles";
import { addShopData, deleteShopData, listShopData, updateShopData } from "../services/shopServices";
import WarningErrorHandling from "../hooks/WarningErrorHandling";
import AddShopDialog from "../components/specific/shop/AddShopDialog";
import ShopTable from "../components/specific/shop/ShopTable";

function Shops({authToken}) {
  const [isshops, setShops] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonloading, setButtonLoading] = useState(false);
  const [shopCodeError, setShopCodeError] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const handleError = useErrorHandle();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const showAlert = useShowAlert();
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchShops = async () => {
    checkNetworkStatus();
    removeData("ShopId");
    removeData("ShopNames");
    setLoading(true);
    try {
      const data = await listShopData(handleError,authToken);

      setShops(data);
      const shopIds = data.map((shop) => shop._id || "");
      const shopNames = data.map((shop) => shop.name || "");
      setData("ShopId", shopIds);
      setData("ShopNames", shopNames);
    } catch (error) {
      const statusCode = error?.response?.status || error?.statusCode;

      if (statusCode !== 404) {
        WarningErrorHandling(error, showAlert);
      } else if (statusCode === 404) {
        setShops([]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchShops();
  }, []);

  const addShop = async (newShop) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const payload = {
        name: newShop.name,
        shopcode: newShop.shopcode,
        password: newShop.password,
        registerno: newShop.registerno,
        address: {
          addressline1: newShop.address.addressline1,
          addressline2: newShop.address.addressline2,
          city: newShop.address.city,
          state: newShop.address.state,
          pincode: newShop.address.pincode,
        },
        billing_address: {
          addressline1: newShop.billing_address.addressline1,
          addressline2: newShop.billing_address.addressline2,
          city: newShop.billing_address.city,
          state: newShop.billing_address.state,
          pincode: newShop.billing_address.pincode,
        },
        gstnumber: newShop.gstnumber,
      };

      if (newShop.phone) {
        payload.phone = newShop.phone;
      }

      const response = await addShopData(payload, handleError,authToken);

      if (response) {
        setShops((prevShops) => {
          return [...(prevShops || []), response];
        });

        showAlert("success", SuccessMsg.SHOP_ADDED);
        closeModal();
      }
    } catch (error) {
      const statusCode = error.response?.data?.statuscode;
      const errorCode = error.response?.data?.code;

      if (statusCode === 409 && errorCode === "DUPLICATE_ENTRY") {
        setErrorMessage(error.response.data.message);
        setShopCodeError(
          "Shop code already exists. Please provide a different code."
        );
      } else {
        WarningErrorHandling(error, showAlert);
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const updateShop = async (updatedShop) => {
    checkNetworkStatus();
    setButtonLoading(true);
    if (!updatedShop._id) {
      WarningErrorHandling(
        "error",
        "Error: shopid is required but not provided."
      );
      return;
    }
    try {
      const payload = {
        shopid: updatedShop._id,
        name: updatedShop.name || undefined,
        shopcode: updatedShop.shopcode || undefined,
        password: updatedShop.password || undefined,
        registerno: updatedShop.registerno || undefined,
        phone: updatedShop.phone || undefined,
        address: {
          addressline1: updatedShop.address?.addressline1 || undefined,
          addressline2: updatedShop.address?.addressline2 || undefined,
          city: updatedShop.address?.city || undefined,
          state: updatedShop.address?.state || undefined,
          pincode: updatedShop.address?.pincode || undefined,
        },
        billing_address: {
          addressline1: updatedShop.billing_address?.addressline1 || undefined,
          addressline2: updatedShop.billing_address?.addressline2 || undefined,
          city: updatedShop.billing_address?.city || undefined,
          state: updatedShop.billing_address?.state || undefined,
          pincode: updatedShop.billing_address?.pincode || undefined,
        },
        gstnumber: updatedShop.gstnumber || undefined,
      };

      const response = await updateShopData(payload, handleError,authToken);

      if (response) {
        setShops((prevShops) => {
          return prevShops.map((shop) =>
            shop._id === response._id ? response : shop
          );
        });

        showAlert("success", SuccessMsg.SHOP_UPDATED);
        closeModal();
      }
    } catch (error) {
      const statusCode = error.response?.data?.statuscode;
      const errorCode = error.response?.data?.code;

      if (statusCode === 409 && errorCode === "DUPLICATE_ENTRY") {
        setErrorMessage(error.response.data.message);
        setShopCodeError(
          "Shop code already exists. Please provide a different code."
        );
      } else {
        WarningErrorHandling(error, showAlert);
      }
    } finally {
      setButtonLoading(false);
    }
  };
  const handleDeleteShop = async (shopId) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const payload = {
        shopid: shopId,
      };

      const data = await deleteShopData(payload, handleError,authToken);
      if (data) {
        console.log("del", data);
        setShops((prevShops) =>
          prevShops.filter((shop) => shop._id !== shopId)
        );
        showAlert("success", SuccessMsg.SHOP_DELETED);
      }
    } catch (error) {
      WarningErrorHandling(error, showAlert);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <div className="page-breadcrumb d-flex align-items-center mb-2">
        <div className="breadcrumb-title "></div>
        <h6>Shop</h6>
      </div>

      {isModalOpen && (
        <AddShopDialog
          onclose={closeModal}
          addShop={addShop}
          loading={buttonloading}
          errorMessage={errorMessage}
          setShopCodeError={setShopCodeError}
          shopCodeError={shopCodeError}
        />
      )}

      <ShopTable
        openModal={openModal}
        data={isshops}
        handleUpdate={updateShop}
        loading={loading}
        updateload={buttonloading}
        handleDelete={handleDeleteShop}
        errorMessage={errorMessage}
        authToken={authToken}
      />
    </div>
  );
}

export default Shops;
