import React, { createContext, useState, useCallback } from "react";

export const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "info",
    message: "",
  });

  const showSnackbar = useCallback(({ severity, message }) => {
    const validSeverities = ["error", "info", "success", "warning"];
    const validSeverity = validSeverities.includes(severity)
      ? severity
      : "info";

    setSnackbar({
      open: true,
      severity: validSeverity,
      message: message || "",
    });
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <SnackbarContext.Provider value={{ snackbar, showSnackbar, closeSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  );
};
