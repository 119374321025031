import React, { useState, useRef } from "react";
import "../../../style/product.css";
import { stopEventPropagation } from "../../../utils/helper";
import CancelButton from "../../reusable/Buttons/CancelButton";
import AddButton from "../../reusable/Buttons/AddButton";
import ButtonWithProgress from "../../reusable/Buttons/ButtonWithProgress";


function AddAgentDialog({ onclose, addUser, buttonloading ,
 
  
}) {
  const [userData, setUserData] = useState({
  name:"",
  phone:"",
  email :"",
  password:"",
  usercode:""
  });
  const [errors, setError] = useState({});
 
  const userNameRef = useRef(null);
  const userPhoneRef = useRef(null);
  const userEmailRef = useRef(null);
  const userCodeRef= useRef(null);
  const userPasswordRef=useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setError("")
    let trimmedValue;
    if (name === "phone" || name === "email" ) {
      trimmedValue = value.replace(/\s/g, "");
    } else {
      trimmedValue = value.trimStart().replace(/\s+/g, " ");
    }
    setUserData((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));
    
  };  
  const validateFields = () => {
    let valid = true;
    const newErrors = {};
  
    // Validate Name
    if (!userData.name) {
      newErrors.name = "This field must not be empty. Please enter a value.";
      valid = false;
      userNameRef.current.focus();
    }
    else if (!userData.phone) {
        newErrors.phone = "This field must not be empty. Please enter a value.";
        valid = false;
        userPhoneRef.current.focus();
      } 
      else if (userData.phone.length !== 10) {
        newErrors.phone = "Phone Number must be 10 digits.";
        valid = false;
        userPhoneRef.current.focus();
      } 

      
      else if (!userData.usercode) {
        newErrors.usercode = "This field must not be empty. Please enter a value.";
        valid = false;
        userCodeRef.current.focus();
      }
    
     
      else if (!userData.password) {
        newErrors.password = "This field must not be empty. Please enter a value.";
        valid = false;
        userPasswordRef.current.focus();
      } else if (userData.password && userData.password.length > 20) {
        newErrors.password = "Password must not exceed 20 characters.";
        valid = false;
    }
    
  
 if (userData.email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userData.email)) {
      newErrors.email = "Please enter a valid email address.";
      valid = false;
      userEmailRef.current.focus();
    } else {
      newErrors.email = "";
    }
  
    // Validate Phone
 
    setError(newErrors);
    return valid;
  };
  
  const handleAdd = () => {

    if (!validateFields()) {
      return;
    }
    
    addUser(userData);
  };

  
  return (
    <div>
      <div className="overlay toggle-icon bc overlay1"></div>
      <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onclose}
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-scrollable"
          onClick={stopEventPropagation}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="modal-content border-0">
            <div className="modal-header">
              <h6 className="modal-title">Add Agent</h6>
              <button
                type="button"
                onClick={onclose}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="customerForm">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      autoComplete="off"
                      value={userData.name}
                      onChange={handleChange}
                      ref={userNameRef}
                    />
                    {errors.name && (
                      <div className="error-message invalid-feedback">
                        {errors.name}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <>
                    <label className="form-label">
                    Phone Number <span className="text-danger">*</span>
                    </label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          id="basic-addon41"
                          style={{
                            backgroundColor: "#ededff",
                            color: "#242c6d !important",
                          }}
                        >
                          +91
                        </span>
                        <input
                          type="text"
                          maxLength={10}
                          className="form-control"
                          fdprocessedid="v93442"
                          autoComplete="off"
                          name="phone"
                          value={userData.phone}
                          ref={userPhoneRef}
                         
                          onChange={(e) => {
                            const value = e.target.value;

                            if (/^\d{0,10}$/.test(value)) {
                              handleChange(e);
                            }
                          }}
                        />
                        {errors.phone && (
                          <div className="error-message invalid-feedback">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">
                     User Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      name="usercode"
                      value={userData.usercode}
                      onChange={handleChange}
                      ref={userCodeRef}
                    />
                    {errors.usercode && (
                      <div className="error-message invalid-feedback">
                        {errors.usercode}
                      </div>
                    )}

                  
                  </div>
               
                  <div className="col-lg-6">
             <div style={{display:'flex', justifyContent:'space-between'}}>
          <label className="form-label">
                      Password<span className="text-danger">*</span>
                    </label>
                    
        </div>
                    
                    <input
                      type= 'text' 
                   
                      className="form-control"
                      name="password"
                      autoComplete="off"
                      value={userData.password}
                      onChange={handleChange}
                      ref={userPasswordRef}
                    />
                      {errors.password && (
                      <div className="error-message invalid-feedback">
                        {errors.password}
                      </div>
                    )}
                  </div>
                  

                  <div className="col-lg-12">
                    <label className="form-label">
                     Email
                      <span className="text-danger"></span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      autoComplete="off"
                    
                      value={userData.email}
                      onChange={handleChange}
                      ref={userEmailRef}
                    />
                    {errors.email && (
                      <div className="error-message invalid-feedback">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className="modal-footer d-block">
              <CancelButton
                buttonText="Cancel"
                buttonIcon="bx bx-x"
                onClick={onclose}
                additionalClasses="btn-outline-secondary text-left"
              />

              {buttonloading ? (
                <ButtonWithProgress
               
               
                  buttonClass="btn btn-primary pull-right"
              
                />
              ) : (
                <AddButton
                  label="Add"
                  iconClass="bx bx-plus"
                  buttonClass="btn btn-primary pull-right"
                  onClick={handleAdd}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAgentDialog;
