import ErrorMsg from "../components/common/messages/Errormessage";
const WarningErrorHandling = (error, showAlert) => {
  if (error.message === "NO_INTERNET") {
    showAlert("error", ErrorMsg.NO_INTERNET);
  } else if (error.code === "ERR_NETWORK") {
    showAlert("error", ErrorMsg.SERVER_DOWN);
  } else if (error.response) {
    const { status, data } = error.response;

    if (status === 406) {
      showAlert(
        "error",
        "Not acceptable. Please check the request parameters."
      );
    } else if (status >= 500) {
      showAlert("error", "Server error. Please try again later.");
    } else {
      showAlert("warning", data?.message || "An unknown error occurred.");
    }
  } else if (error.name === "AbortError") {
    console.warn("Fetch request was cancelled.");
    showAlert("info", "Request was cancelled.");
  } else if (
    error.response &&
    error.response.data?.message &&
    !error.response.data.results.msg
  ) {
    if (!error.response.data.code === "NOT_FOUND") {
      showAlert("warning", error.response.data.message);
    }
  } else if (
    error.response &&
    error.response.data?.message &&
    error.response.data.results.msg
  ) {
    showAlert("warning", error.response.data.results.msg);
  } else if (error.code === "ERR_BAD_REQUEST") {
    showAlert("warning", ErrorMsg.URL_NOT_FOUND);
  } else {
    showAlert("warning", error.message || ErrorMsg.SERVER_ERROR);
  }
};

export default WarningErrorHandling;
