import axios from "axios";

const api_url = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;
export const listShopOrder = async (playload,handleError,authToken) => {
  const axiosInstances = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstances.post("list-shop-order",playload);
   let data=response.data.results;
        return data;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};

export const ViewShopOrder = async (playload,handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
    try {
      const response = await axiosInstance.post("view-shop-order",playload);
     let data=response.data.results;
          return data;
    } catch (error) {
      if (error.response) {
        handleError(error.response);
      }
      throw error;
    }
  };

  
export const updateOrderStatus = async (playload,handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
    try {
      const response = await axiosInstance.put("update-order-status",playload);
     let data=response.data.results;
          return data;
    } catch (error) {
      if (error.response) {
        handleError(error.response);
      }
      throw error;
    }
  };