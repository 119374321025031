import { useNavigate } from 'react-router-dom';
import { createToken } from '../utils/CreateApi';

const useErrorHandle = () => {
  const navigate = useNavigate();

  const handleError = async (response) => {
    if (response.status === 401 ) {
      try {
        const tokenCreated = await createToken(navigate); 
        if (!tokenCreated) {
          console.log("no refresh token")
          navigate('/login');
        }
      } catch (error) {
        console.error('Failed to create token:', error);
        navigate('/login');
      }
    }  else {
      console.error("An unknown error occurred.");
    }
  };

  return handleError;
};

export default useErrorHandle;
