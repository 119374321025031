import { useEffect, useContext } from "react";
import ErrorMsg from "../../common/messages/Errormessage";
import SuccessMsg from "../../common/messages/SuccessMessage";
import { SnackbarContext } from "../../../contexts/SnackbarContext";

const NetworkStatusMonitor = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const notifyOffline = () => {
      showSnackbar({
        severity: "error",
        message: ErrorMsg.NO_INTERNET,
      });
    };

    if (!navigator.onLine) {
      notifyOffline();
    }

    const handleOnline = () => {
      showSnackbar({
        severity: "success",
        message: SuccessMsg.CONNECTION_RESTORED,
      });
      const snackbarDisplayTime = 1000;
      setTimeout(() => {
        window.location.reload();
      }, snackbarDisplayTime);
    };

    const handleOffline = () => {
      notifyOffline();
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [showSnackbar]);

  return null;
};

export default NetworkStatusMonitor;