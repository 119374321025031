import React, { useState } from "react";
import EditButton from "../../reusable/EditIcon";
import DeleteButton from "../../reusable/DeleteIcon";

import EditAgentDialog from "./EditAgentDialog";
import DeletePopup from "../../common/dialog/DeletePopup";
import AddButton from "../../reusable/Buttons/AddButton";
import TableSkeleton from "../../common/table/TableSkeleton";
import TableHeader from "../../common/table/TableHeader";



const UserTable = ({openModal, data = [], handleUpdate ,handleDelete,loading ,buttonloading}) => {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const headers = [
    "S.No",
    "User Name",
    "Password",
    "Name",
    "Phone Number",
    "Email",
    "Action",
  ];
  const filteredData = data.filter((agent) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (agent.name && agent.name.toLowerCase().includes(searchLower)) ||
      (agent.usercode && agent.usercode.toLowerCase().includes(searchLower)) ||
      (agent.phone && agent.phone.toLowerCase().includes(searchTerm)) ||
      (agent.email && agent.email.toLowerCase().includes(searchTerm)) 
      
    );
  });
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedAgent(null);
  };

  const openEditModal = (product) => {
   
    setSelectedAgent(product);
    setIsDialogOpen(true);
  };
  const openDeleteDialog = (product) => {
    setSelectedAgent(product);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    handleDelete(selectedAgent._id);
    setIsDeleteDialogOpen(false); 
  };
  const UpdateProduct = (updatedProductData) => {
    console.log("updates",updatedProductData.userid);
    
    handleUpdate(updatedProductData);
    handleCloseDialog();
  };
  
  return (
    <>
      <div className="row g-2">
        <div className="col-lg-12">
          <div className="card">
          <div className="card-header m-1">
            
 <div className="d-flex align-items-center justify-content-between gap-2">
              <div className="col-sm-8">
                
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search ..."
                    fdprocessedid="vlw39b"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className="input-group-text"
                    fdprocessedid="1vtzoc"
                    style={{ backgroundColor: "#ededff" }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>

              <div className="col-sm-4  d-flex justify-content-end">
          
          <div className="btn-group">
          <AddButton label=" Add Agent"
                      iconClass="bx bx-plus"
                      buttonClass="btn btn-primary pull-right"
                      onClick={openModal}/>
           

          

          
        </div> 
              </div>
            </div> 
     
            
            
            
             </div>







            
            <div className="card-body p-0">
              <div className="table-responsive"  style={{
                  flex: '1', 
                  overflowY: 'auto', 
                  maxHeight: 'calc(100vh - 200px)', 
                }} >
                <table className="table align-middle mb-0" >
                              <TableHeader headers={headers} />
                  <tbody>
                  {loading ? (
                      <TableSkeleton
                      rows={5}
                      columns={[
                        40, 80, 500, 200, 300, 100, 100
                      ]}
                    />
                      
                    ) :Array.isArray(filteredData) && filteredData.length > 0 ? (
                      filteredData.map((user, index) => (
                      <tr key={`${user._id}-${index}`}>
                        <td className="text-center">{index + 1}</td>

                        <td className="text-center">
                            <span
                              style={{
                                color: "#007cf3",
                                backgroundColor: "rgb(13 110 253 / .11)",
                              }}
                              className="badge rounded-pill "
                            >
                              {user.usercode}
                            </span>
                          </td>
                          <td className="text-center">
                            <span
                             
                              className="badge rounded-pill bg-label-blue"
                            >
                              {user.password}
                            </span>
                          </td>
                        <td className="text-center">
                          <h6 className="mb-0 fw-500">
                          {user.name.charAt(0).toUpperCase() + user.name.slice(1)}
                       
                            
                            </h6>
                        </td>
                     

                        
                      
                        <td className="text-center">{user.phone}</td>
                          
                  
                          
                        <td className="text-center">{user.email || "-"}</td>
                   
                        <td>
                          <div className="d-block order-actions text-center">
                            <EditButton onEdit={() => openEditModal(user)} />
                            <DeleteButton onDelete={() => openDeleteDialog(user)}/>
                          </div>
                        </td>
                      </tr>
                     ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No Data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <EditAgentDialog
        editData={selectedAgent}
          onClose={handleCloseDialog}
          editUser={UpdateProduct}
          buttonloading={buttonloading}
        />
      )}

{isDeleteDialogOpen && (
        <DeletePopup
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
           Name="agent"
           buttonloading={buttonloading}
         
        />
      )}
    </>
  );
};

export default UserTable;
