
import React, { useState, useRef, useEffect } from "react";
import Profile from "../../../assets/image/avatar-2.png";
import { Link, useNavigate } from "react-router-dom";
import '../../../style/navbar.css';
import { getData } from "../../../utils/CookieUtiles";

function NavBar({ setLoggedIn, setDrawerOpen, drawerOpen }) {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); 
  const navigate = useNavigate();
const cookie_userEmail=getData("Email");
const cookie_userRoll=getData("RollNo");

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleLogout = () => {
    setLoggedIn(true);
    navigate('/');
  };
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };


    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
       <div className={` ${drawerOpen ? "wrapper toggled" : "wrapper "}`}>
      <div className="header-wrapper">
        <header>
          <div className="topbar d-flex align-items-center">
            <nav className="navbar navbar-expand gap-3">
              <div className="topbar-logo-header d-none d-lg-flex"></div>
              <div className="mobile-toggle-menu" onClick={toggleDrawer}>
                <i className="bx bx-menu"></i>
              </div>

              <div className="top-menu ms-auto">
                <ul className="navbar-nav align-items-center gap-1">
                  {/* Other menu items */}
                </ul>
              </div>

              <div className="user-box dropdown px-3" ref={dropdownRef}>
                <div className="dropdown">
                  <Link
                    to="#"
                    className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret"
                    role="button"
                    aria-expanded={isDropdownOpen}
                    onClick={toggleDropdown}
                  >
                    <div className="profile-box">
                      <img
                        src={Profile}
                        className="user-img"
                        alt="user avatar"
                      />
                    </div>
                    <div className="user-info">
                      <p className="user-name mb-0">{cookie_userEmail}</p>
                      <p className="designattion mb-0">{cookie_userRoll}</p>
                    </div>
                  </Link>

                  {/* Dropdown Menu */}
                  {isDropdownOpen && (
                    <>
                      <ul className="dropdown-menu dropdown-menu-end show">
                        <li>
                          <Link
                            className={`dropdown-item ${
                              hoveredItem === "profile" ? "hovered" : ""
                            }`}
                            to="/profile"
                           
                            onMouseEnter={() => handleMouseEnter("profile")}
                            onMouseLeave={handleMouseLeave}
                            onClick={(e) => {
                              e.stopPropagation(); 
                              toggleDropdown(); 
                            }}
                          >
                            <i className='bx bxs-user fs-5'></i>  Profile
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={`dropdown-item ${
                              hoveredItem === "settings" ? "hovered" : ""
                            }`}
                            to="/settings"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              toggleDropdown(); 
                            }}
                            onMouseEnter={() => handleMouseEnter("settings")}
                            onMouseLeave={handleMouseLeave}
                          >
                            <i className='bx bxs-cog fs-5'></i> Settings
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <Link
                            className={`dropdown-item ${
                              hoveredItem === "logout" ? "hovered" : ""
                            }`}
                            onClick={handleLogout}
                            onMouseEnter={() => handleMouseEnter("logout")}
                            onMouseLeave={handleMouseLeave}
                          >
                           <i className='bx bx-log-out fs-5' ></i> Logout
                          </Link>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </nav>
          </div>
        </header>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
