
import { getData, removeData, setData } from "./CookieUtiles";
const apiUrl_Alagappa = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;
export const createToken = async (navigate,setLoggedIn) => {

   removeData("AccessToken");
    try {
        const refreshToken =  getData("RefreshToken");;
        if (!refreshToken) {
            setLoggedIn(false)
            navigate('/login');
 
            return false;
        }

        const response = await fetch(`${apiUrl_Alagappa}create-account-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${refreshToken}`,
            },
        });

        if (response.status === 401) {
          removeData("RefreshToken");
            setLoggedIn(false)
            console.log("401")
            navigate('/login');
            return false;
        }

        const data = await response.json();

   
        if (response.ok && data.results && data.results.accessToken) {
      
             setData("AccessToken", data.results.accessToken);
            verifiedToken(navigate);
            return true;
        } else {
       
            setLoggedIn(false)
            navigate('/login');
            return false;
        }
    } catch (error) {
        console.error("Error creating token:", error);
        setLoggedIn(false)
        navigate('/login');
        return false;
    }
};

export const verifiedToken = async (navigate) => {
   
    try {
        const accessToken = getData("AccessToken");
     
        const response = await fetch(`${apiUrl_Alagappa}auth-account-token`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        });

        if (response.status === 401) {
            const tokenCreated = await createToken(navigate);
            if (!tokenCreated) {
                navigate('/login');
            }
            return;
        }

        const data = await response.json();
        if (response.ok && data.status && data.code === "VERIFIED") {
            setData("Email", data.results.email);
            setData("RollNo", data.results.role);
          
            
           
        } else {
            const tokenCreated = await createToken(navigate);
            if (!tokenCreated) {
                navigate('/login');
            }
        }
    } catch (error) {
        
        navigate('/login');
    }
};
