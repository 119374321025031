import axios from "axios";

const api_url = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;

export const listProductData = async (handleError,authToken) => {
  const axiosInstances = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstances.get("list-product");
   let data=response.data.results;
        return data;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};

export const addProductData = async (payload, handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstance.post("add-product", payload);
    return response.data.results;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};

export const updateProductData = async (payload, handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstance.put("update-product", payload);
    return response.data.results;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};

export const deleteProductData = async (playload, handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstance.delete("/delete-product", {
      data: playload,
    });
    return response;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};

export const updateStoke = async (payload, handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstance.put("update-stock", payload);
    return response.data.results;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};
