import axios from "axios";
const api_url = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;

export const listUser = async (handleError,authToken) => {
  const axiosInstances = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstances.get("list-user");
   let data=response.data.results;
        return data;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};

export const addAgentData = async (payload, handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstance.post("add-user", payload);
    return response.data.results;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};

export const updateAgentData = async (payload, handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstance.put("update-user", payload);
    return response.data.results;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};

export const deleteAgentData = async (playload, handleError,authToken) => {
  const axiosInstance = axios.create({
    baseURL: api_url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  try {
    const response = await axiosInstance.delete("/delete-user", {
      data: playload,
    });
    return response;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};
