import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import NavBar from "./components/layout/header/NavBar";
import SideMenu from "./components/layout/menu/SideMenu";
import { createToken } from "./utils/CreateApi";
import { getData, removeData } from "./utils/CookieUtiles";
import NetworkStatusMonitor from "./components/common/networkStatus/NetworkStatusMonitor";
import { SnackbarProvider } from "./contexts/SnackbarContext";
import SnackbarComponent from "./components/common/snakebar/SnakebarComponnet";
import DynamicTitleAndIcon from "./features/dashbord/DynamicTitle";
import Login from "./features/authentication/Login";
import Dashborad from "./features/dashbord/Dashborad";
import Orders from "./pages/Orders";
import Agent from "./pages/Agent";
import Product from "./pages/Product";
import Shops from "./pages/Shops";
function App() {
  return (
    <SnackbarProvider>
      <SnackbarComponent />
      <NetworkStatusMonitor />
      <DynamicTitleAndIcon />
      <MainAppContent />
    </SnackbarProvider>
  );
}
function MainAppContent() {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(() => {
    const token = getData("AccessToken");
    return !!token;
  });

  const [authToken, setAuthToken] = useState(getData("AccessToken"));
  const handleLogin = () => {
    setLoggedIn(true);
    navigate("/");
  };

  useEffect(() => {
    const token = getData("AccessToken");
    if (token) {
      setAuthToken(token);
    } else if (!token) {
      createToken(navigate, setLoggedIn);
      const token = getData("AccessToken");
      setAuthToken(token);
    }

    const refreshToken = getData("AccessToken");
    if (!refreshToken) {
      handleLogout();
    }
  }, [authToken, getData("AccessToken")]);

  const handleLogout = () => {
    navigate("/login");
    setLoggedIn(false);
    removeData("AccessToken");
    removeData("RefreshToken");
  };

  const handleResize = () => {
    if (window.innerWidth >= 1280) {
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <NetworkStatusMonitor />
      <DynamicTitleAndIcon />
      {!loggedIn ? (
        <Login setLoggedIn={handleLogin} to="/login" />
      ) : (
        <div className="app">
          <NavBar
            setDrawerOpen={setDrawerOpen}
            drawerOpen={drawerOpen}
            setLoggedIn={handleLogout}
          />
          <div className="main">
            {drawerOpen && (
              <SideMenu open={drawerOpen} setOpen={setDrawerOpen} />
            )}
            <div
              className={`content ${
                drawerOpen ? "content-open" : "content-closed"
              }`}
            >
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashborad />} />
                <Route path="/shop" element={<Shops authToken={authToken} />} />
                <Route path="/product" element={<Product authToken={authToken}/>} />
                <Route path="/agent" element={<Agent authToken={authToken}/>} />
                <Route
                  path="/order"
                  element={<Orders authToken={authToken} />}
                />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
