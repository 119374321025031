import React, { useEffect, useState } from "react";
import "../style/product.css";



import SuccessMsg from "../components/common/messages/SuccessMessage";
import useErrorHandle from "../hooks/ErrorHandling";
import useShowAlert from "../utils/AlertUtiles";
import checkNetworkStatus from "../components/common/networkStatus/CheckNetworkStatus";
import UserTable from "../components/specific/agent/UserTable";
import AddAgentDialog from "../components/specific/agent/AddAgentDialog";
import WarningErrorHandling from "../hooks/WarningErrorHandling";
import { addAgentData, deleteAgentData, listUser, updateAgentData } from "../services/agentServices";


function Agent({authToken}) {
  const [isUserData, setUserData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shopCodeError, setShopCodeError] = useState();
  const [buttonloading, setButtonLoading] = useState(false);
  const handleError = useErrorHandle();
  const showAlert = useShowAlert();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fetchUserData = async () => {
    checkNetworkStatus();
    setLoading(true);
    try {
      const AgentData = await listUser(handleError,authToken);
      const activeAgent = AgentData.filter(
        (agent) => agent.status === "ACTIVE"
      );
      setUserData(activeAgent);
    } catch (error) {
      const statusCode = error?.response?.status || error?.statusCode;

      if (statusCode !== 404) {
        WarningErrorHandling(error, showAlert);
      } else if (statusCode === 404) {
        setUserData([]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const addUser = async (newProduct) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const payload = {
        name: newProduct.name,
        phone: newProduct.phone,
        password: newProduct.password,
        usercode: newProduct.usercode,
      };
      if (newProduct.email) {
        payload.email = newProduct.email;
      }
      const data = await addAgentData(payload, handleError,authToken);

      setUserData((preUser) => {
        return [...(preUser || []), data];
      });

      showAlert("success", SuccessMsg.AGENT_ADDED);
      closeModal();
    } catch (error) {
      WarningErrorHandling(error, showAlert);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleUpdate = async (newagent) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const payload = {
        userid: newagent.userid,
        name: newagent.name,
        phone: newagent.phone,
        password: newagent.password,
        usercode: newagent.usercode,
      };
      if (newagent.email) {
        payload.email = newagent.email;
      }
      const data = await updateAgentData(payload, handleError,authToken);

      setUserData((prevAgent) => {
        return prevAgent.map((agent) =>
          agent._id === data._id ? data : agent
        );
      });
      showAlert("success", SuccessMsg.AGENT_UPDATED);
      closeModal();
    } catch (error) {
      WarningErrorHandling(error, showAlert);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleDelete = async (id) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const payload = {
        userid: id,
      };

      const data = await deleteAgentData(payload, handleError,authToken);
      if (data) {
        setUserData((preAgent) => {
          const updatedProducts = preAgent.filter((agent) => agent._id !== id);

          return updatedProducts;
        });
        showAlert("success", SuccessMsg.AGENT_DELETED);
        closeModal();
      }
    } catch (error) {
      WarningErrorHandling(error, showAlert);
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <div className="page-breadcrumb d-flex align-items-center mb-2">
        <div className="breadcrumb-title "></div>
        <h6>Agent</h6>
      </div>

      {isModalOpen && (
        <AddAgentDialog
          onclose={closeModal}
          addUser={addUser}
          buttonloading={buttonloading}
          setShopCodeError={setShopCodeError}
          shopCodeError={shopCodeError}
        />
      )}

      <UserTable
        openModal={openModal}
        data={isUserData}
        loading={loading}
        buttonloading={buttonloading}
        handleUpdate={handleUpdate}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default Agent;
