import React from "react";
import Skeleton from "@mui/material/Skeleton";


const TableSkeleton = ({ rows = 5, columns = []}) => {
  return (
<>
      {[...Array(rows)].map((_, rowIndex) => (
        <tr key={rowIndex}>
          {columns.map((width, colIndex) => (
            <td className="text-center" key={colIndex}>
              <Skeleton variant="text" width={width} />
            </td>
          ))}
        </tr>
      ))}
 </>
  );
};


export default TableSkeleton;
