import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import icon from '../../assets/image/faviconhead.png'
const DynamicTitleAndIcon = () => {
  const location = useLocation();

  const updateTitleAndIcon = (pathname) => {
    let title = 'Alagappa Aagencies - Dashboard';
    let iconPath = {icon} 

    switch (pathname) {
      case '/customers':
        title = 'Alagappa Aagencies - Customers';
        break;
      case '/Product':
        title = 'Alagappa Aagencies - Product';
        iconPath = {iconPath}
        break;
      case '/shop':
        title = 'Alagappa Aagencies - Shop';
        iconPath = {iconPath}
        break;
        case '/agent':
        title = 'Alagappa Aagencies - Agent';
        iconPath = {iconPath}
        break;
      case '/order':
        title = 'Alagappa Aagencies - Order';
        iconPath = {iconPath}
        break;
      case '/login':
            title = 'Alagappa Aagencies - Login';
            iconPath = {iconPath}
            break;
     
     
      default:
        title = 'Alagappa Aagencies - Dashboard';
    }

    const favicon = document.getElementById('favicon');
    if (favicon) {
      favicon.href = iconPath;
    }

    document.title = title;
  };

  useEffect(() => {
    updateTitleAndIcon(location.pathname);
  }, [location.pathname]);

  return null; 
};

export default DynamicTitleAndIcon;
