import React, { useEffect, useState } from "react";
import "../style/product.css";
import SuccessMsg from "../components/common/messages/SuccessMessage";
import useErrorHandle from "../hooks/ErrorHandling";
import useShowAlert from "../utils/AlertUtiles";
import checkNetworkStatus from "../components/common/networkStatus/CheckNetworkStatus";
import { addProductData, deleteProductData, listProductData, updateProductData } from "../services/productServices";
import WarningErrorHandling from "../hooks/WarningErrorHandling";
import AddProductDialog from "../components/specific/Products/AddProductDialog";
import ProductTable from "../components/specific/Products/ProductTable";

function Product({authToken}) {
  const [isProduct, setProduct] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
   const [loading, setLoading] = useState(false);
  const [shopCodeError, setShopCodeError] = useState();
  
  const [buttonloading, setButtonLoading] = useState(false);
  const handleError = useErrorHandle();
  const showAlert = useShowAlert();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const fetchProducts = async () => {
    checkNetworkStatus();
    setLoading(true);
    try {
      const data = await listProductData(handleError,authToken);
      if (data) {
        setProduct(data);
      }
    } catch (error) {
      const statusCode = error?.response?.status || error?.statusCode;

      if (statusCode !== 404) {
        WarningErrorHandling(error, showAlert);
      } else if (statusCode === 404) {
        setProduct([]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const addProduct = async (newProduct) => {
    checkNetworkStatus();
    setButtonLoading(true);
    if (
      !newProduct.code ||
      !newProduct.mrp || !newProduct.basePrice ||
      !newProduct.saleprice || !newProduct.taxAmount ||
      !newProduct.title
    ) {
      WarningErrorHandling("warning", "All Fields are Required");
      return;
    }
    try {
      const payload = {
        code: newProduct.code,
        mrp: newProduct.mrp,
        saleprice: newProduct.saleprice,
        title: newProduct.title,
        taxamount: newProduct.taxAmount|| 0,
        baseprice: newProduct.basePrice,
        taxpercentage: newProduct.taxpercentage || 0,
      };

      const response = await addProductData(payload, handleError,authToken);

      if (response) {
        setProduct((prevShops) => {
          return [...(prevShops || []), response];
        });

        showAlert("success", SuccessMsg.PRODUCT_ADDED);
        closeModal();
      }
    } catch (error) {
      const statusCode = error.response?.data?.statuscode;
      const errorCode = error.response?.data?.code;

      if (statusCode === 409 && errorCode === "DUPLICATE_ENTRY") {
        setShopCodeError(
          "Shop code already exists. Please provide a different code."
        );
      } else {
        WarningErrorHandling(error, showAlert);
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const handleUpdateProduct = async (updatedProduct) => {
    checkNetworkStatus();
    setButtonLoading(true);
    if (
      !updatedProduct.code ||
      !updatedProduct.mrp ||
      !updatedProduct.saleprice ||!updatedProduct.taxAmount ||!updatedProduct.basePrice ||
      !updatedProduct.title
    ) {
      WarningErrorHandling("warning", "All Fields are Required");
      return;
    }
    try {
      const payload = {
        productid: updatedProduct.productid,
        code: updatedProduct.code,
        mrp: updatedProduct.mrp,
        saleprice: updatedProduct.saleprice,
        title: updatedProduct.title,
        taxamount: updatedProduct.taxAmount|| 0,
        baseprice: updatedProduct.basePrice,
        taxpercentage: updatedProduct.taxpercentage || 0,
      };

      const response = await updateProductData(payload, handleError,authToken);

      if (response) {
        setProduct((prevProducts) => {
          return prevProducts.map((product) =>
            product._id === response._id ? response : product
          );
        });

        showAlert("success", SuccessMsg.PRODUCT_UPDATED);
        closeModal();
      }
    } catch (error) {
      const statusCode = error.response?.data?.statuscode;
      const errorCode = error.response?.data?.code;

      if (statusCode === 409 && errorCode === "DUPLICATE_ENTRY") {
        setShopCodeError(
          "Shop code already exists. Please provide a different code."
        );
      } else {
        WarningErrorHandling(error, showAlert);
      }
    } finally {
      setButtonLoading(false);
    }
  };

  const handleDeleteProduct = async (productId) => {
    checkNetworkStatus();
    setButtonLoading(true);
    try {
      const payload = {
        productid: productId,
      };

      const data = await deleteProductData(payload, handleError,authToken);
      if (data) {
        setProduct((prevProducts) => {
          const updatedProducts = prevProducts.filter(
            (product) => product._id !== productId
          );

          return updatedProducts;
        });
        showAlert("success", SuccessMsg.PRODUCT_DELETED);
      }
    } catch (error) {
      WarningErrorHandling(error, showAlert);
    } finally {
      setButtonLoading(false);
    }
  };



  return (
    <div>
      <div className="page-breadcrumb d-flex align-items-center mb-2">
        <div className="breadcrumb-title "></div>
        <h6>Product</h6>
      </div>

      {isModalOpen && (
        <AddProductDialog
          onclose={closeModal}
          addProduct={addProduct}
          buttonloading={buttonloading}
          setShopCodeError={setShopCodeError}
          shopCodeError={shopCodeError}
        />
      )}

      <ProductTable
        openModal={openModal}
        data={isProduct}
        handleUpdate={handleUpdateProduct}
        handleDelete={handleDeleteProduct}
        loading={loading}
        buttonloading={buttonloading}
        authToken={authToken}
      />

     
    </div>
  );
}

export default Product;
