export const stopEventPropagation = (e) => {
    e.stopPropagation();
  };
  export const calculateTaxAndSalePrice = (basePrice, taxPercentage) => {
    const basePriceValue = parseFloat(basePrice) || 0;
    const taxAmount = (basePriceValue / 100) * parseFloat(taxPercentage || 0);
    const salePrice = basePriceValue + taxAmount;
  
    return {
      taxAmount: taxAmount.toFixed(2),
      salePrice: salePrice.toFixed(2),
    };
  };