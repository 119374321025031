import Cookies from 'js-cookie';

const COOKIE_KEY = 'pharmacy-admin-panel'
const setCookieData = (key, value, options = {}) => {
  const currentData = getCookieData();
  currentData[key] = value; 

  Cookies.set(COOKIE_KEY, JSON.stringify(currentData), {
    expires: 365, 
    secure: true,
    sameSite: 'Strict',
    path: '/',
    ...options,
  });
};
const getCookieData = () => {
  try {
    const data = Cookies.get(COOKIE_KEY);

    return data ? JSON.parse(data) : {};
  } catch (error) {
    console.error('Error parsing cookie data:', error);
    return {};
  }
};
const removeCookieDataKey = (key, options = {}) => {
  const currentData = getCookieData();
  delete currentData[key]; 

  if (Object.keys(currentData).length > 0) {

    Cookies.set(COOKIE_KEY, JSON.stringify(currentData), {
      expires: 365, 
      secure: true,
      sameSite: 'Strict',
      path: '/',
      ...options,
    });
  } else {

    Cookies.remove(COOKIE_KEY, { path: '/', ...options });
  }
};


export const setData = (key, value) => {
  setCookieData(key, value);
};

export const getData = (key) => {
  const data = getCookieData();
  return data[key];
};

export const removeData = (key) => {
  removeCookieDataKey(key);
};

export const clearAllData = () => {
  Cookies.remove(COOKIE_KEY, { path: '/' });
};
