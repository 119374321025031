import React, { useEffect, useState } from "react";
import "../../../style/order.css";
import { getData } from "../../../utils/CookieUtiles";
import TableHeader from "../../common/table/TableHeader";
import TableSkeleton from "../../common/table/TableSkeleton";

const OrderTable = ({ data = [], loading, onView, listData }) => {
  const ShopIds = getData("ShopId");
  const [IsSelectedStatus, setSelectedStatus] = useState("CREATED");
  const headers = [
    "S.No",
    "Date",
    "Shop",
    "Item",
    "Grand Total",
    "Status",
    "Action",
  ];
  const ShopNames = getData("ShopNames");
  const [selectedShopName, setSelectedShopName] = useState("");
  const [selectedShopId, setSelectedShopId] = useState("");
  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "bg-label-success1";
      case "created":
        return "bg-label-info";
      case "accepted":
        return "bg-label-blue";
      default:
        return "bg-label-default";
    }
  };
  const formatOrderDate = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(" ", "-")
      .replace(" ", "-")
      .toUpperCase();
  };

  const formatNumber = (number) => {
    return number.toFixed(2);
  };
  const handleShopChange = (event) => {
    const shopId = event.target.value;
    setSelectedShopId(shopId);
    const index = ShopIds.indexOf(shopId);
    setSelectedShopName(index !== -1 ? ShopNames[index] : "");

    listData(shopId, IsSelectedStatus);
  };

  const handleStatusChange = (event) => {
    const statusChange = event.target.value;
    setSelectedStatus(statusChange);

    listData(selectedShopId, statusChange);
  };

  useEffect(() => {}, [selectedShopName]);

  return (
    <>
      <div className="row g-2">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header py-3">
              <div className="row g-3 align-items-center">
                <div className="col-sm-5">
                  <h6 className="mb-0">Order List</h6>
                </div>

                <div className="col-sm-4">
                  <select
                    onChange={handleShopChange}
                    value={selectedShopId}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value="">Select Shop</option>

                    {Array.isArray(ShopIds) &&
                      Array.isArray(ShopNames) &&
                      ShopIds.filter((id, index) => id && ShopNames[index]).map(
                        (id, index) => (
                          <option key={id} value={id}>
                            {ShopNames[index]}
                          </option>
                        )
                      )}
                  </select>
                </div>

                <div className="col-sm-3">
                  <select
                    onChange={handleStatusChange}
                    value={IsSelectedStatus}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option value=""> Select status </option>
                    <option value="CREATED"> CREATED </option>
                    <option value="ACCEPTED"> ACCEPTED </option>
                    <option value="COMPLETED"> COMPLETED </option>
                    <option value="CANCELLED"> CANCELLED </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div
                className="table-responsive"
                style={{
                  flex: "1",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 200px)",
                }}
              >
                <table className="table align-middle mb-0">
                  <TableHeader headers={headers} />

                  <tbody>
                    {loading ? (
                      <TableSkeleton
                        rows={5}
                        columns={[40, 100, 100, 60, 60, 100, 100]}
                      />
                    ) : Array.isArray(data) && data.length > 0 ? (
                      data.map((order, index) => {
                        const orderDate = order.orderdate
                          ? formatOrderDate(order.orderdate)
                          : "N/A";

                        return (
                          <tr key={`${order.id}-${index}`}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">
                              <span className="badge rounded-pill bg-light text-primary">
                                {orderDate}
                              </span>
                            </td>
                            <td className="text-center">
                              {order.shopid.name || ""}
                            </td>
                            <td className="text-center">
                              <h6 className="mb-0 fw-500">
                                {order.cart_data.length || "0"} Items
                              </h6>
                            </td>
                            <td className="text-center">
                              ₹
                              {order.grandtotal
                                ? formatNumber(order.grandtotal)
                                : "N/A"}
                            </td>
                            <td className="text-center">
                              <span
                                className={`badge rounded-pill ${getColor(
                                  order.status
                                )}`}
                              >
                                {order.status ? order.status : "N/A"}
                              </span>
                            </td>
                            <td>
                              <div className="d-block order-actions text-center">
                                <button
                                  type="button"
                                  className=""
                                  onClick={() => onView(order)}
                                >
                                  <i className="bx bx-show"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center text-muted">
                          No orders found in this shop
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderTable;
