import React, { useState, useEffect, useRef } from "react";
import "../../../style/product.css";

import CancelButton from "../../reusable/Buttons/CancelButton";
import AddButton from "../../reusable/Buttons/AddButton";
import ButtonWithProgress from "../../reusable/Buttons/ButtonWithProgress";
import { calculateTaxAndSalePrice, stopEventPropagation } from "../../../utils/helper";

function EditProduct({ onClose, updateProduct, productData, buttonloading }) {
  const [isProductData, setProductData] = useState({
    productid: "",
    title: "",
    code: "",
    mrp: "",
    basePrice: "",
    taxAmount:"",
    saleprice: "",
    taxpercentage:""
  });
  const productTaxRef=useRef(null);
  const [errors, setError] = useState({});
  const productCodeRef = useRef(null);
  const productTitleRef = useRef(null);
  const productMrpRef = useRef(null);
  const productbasepriceRef = useRef(null);
  useEffect(() => {
    if (productData) {
      setProductData({
        productid: productData._id,
        code: productData.code,
        title: productData.title,
        mrp: productData.mrp,
        saleprice: productData.saleprice,
        basePrice: productData.baseprice,
        taxAmount: productData.taxamount,
        taxpercentage:productData.taxpercentage

      });
    }
  }, [productData]);
  const handleTaxPercentageChange = (e) => {
    const newTaxPercentage = parseFloat(e.target.value);
  //  setProductData((prevData) => ({
  //     ...prevData,
  //     taxpercentage: newTaxPercentage,
  //   }));
  setProductData((prevData) => {
      const { taxAmount, salePrice } = calculateTaxAndSalePrice(prevData.basePrice, newTaxPercentage);
  
      return {
        ...prevData,
        taxpercentage: newTaxPercentage,
        taxAmount,
        saleprice: salePrice,
      };
    });
    
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
   
    let trimmedValue;
    if (name === "code" || name === "mrp" || name === "baseprice") {
      trimmedValue = value.replace(/\s/g, ""); 
  } else {
      trimmedValue = value.trimStart().replace(/\s+/g, ' '); 
  };

    setProductData((prevData) => ({
      ...prevData,
      [name]: trimmedValue,
    }));
  };

  const validateFields = () => {
    let valid = true;
    const newErrors = {};

    if (!isProductData.title) {
      newErrors.title = "This field must not be empty. Please enter a value.";
      valid = false;
      productTitleRef.current.focus();
    } else if (!isProductData.code) {
      newErrors.code = "This field must not be empty. Please enter a value.";
      valid = false;
      productCodeRef.current.focus();
    } else if (!isProductData.mrp) {
      newErrors.mrp = "This field must not be empty. Please enter a value.";
      valid = false;
      productMrpRef.current.focus();
    } else if (!isProductData.basePrice) {
      newErrors.basePrice =
        "This field must not be empty. Please enter a value.";
      valid = false;
      productbasepriceRef.current.focus();
    }
    setError(newErrors);
    return valid;
  };
  const handleUpdate = () => {
    const { basePrice, mrp } = isProductData;
    const basePriceValue = parseFloat(basePrice);
    const mrpValue = parseFloat(mrp);

    if (!validateFields()) {
      return;
    }
    if (basePriceValue >= mrpValue) {
      setError({
        basePrice: "*Base price must be less than MRP",
        mrp: "",
      });
     
      return;
    }

  
    updateProduct(isProductData);
  };



  const handleBasePriceChange = (event) => {
    const { value } = event.target;

    let sanitizedValue = value.replace(/[^0-9.]/g, "");
    const parts = sanitizedValue.split(".");

    if (sanitizedValue.length > 12) {
      sanitizedValue = sanitizedValue.slice(0, 12);
    }

    if (parts.length > 2) {
      sanitizedValue = parts[0] + "." + parts.slice(1).join("");
    }

    if (parts.length === 2 && parts[1].length > 2) {
      sanitizedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    // setProductData((prevData) => ({
    //   ...prevData,
    //   basePrice: sanitizedValue,
    // }));
    setProductData((prevData) => {
         const { taxAmount, salePrice } = calculateTaxAndSalePrice(sanitizedValue, prevData.taxpercentage);
     
         return {
           ...prevData,
           basePrice: sanitizedValue,
           taxAmount,
           saleprice: salePrice,
         };
       });
    const mrpValue = parseFloat(isProductData.mrp);
    const basePriceValue = parseFloat(sanitizedValue) || 0;

    if (basePriceValue >= mrpValue) {
      setError((prevErrors) => ({
        ...prevErrors,
       basePrice : "Base price must be less than MRP",
        mrp: "",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        basePrice: "",
      }));
    }

    if (mrpValue <= basePriceValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "MRP must be greater than Base price",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "",
      }));
    }
  };

  const handleMrpChange = (event) => {
    const { value } = event.target;

    let sanitizedValue = value.replace(/[^0-9.]/g, "");
    const parts = sanitizedValue.split(".");

    if (sanitizedValue.length > 12) {
      sanitizedValue = sanitizedValue.slice(0, 12);
    }

    if (parts.length > 2) {
      sanitizedValue = parts[0] + "." + parts.slice(1).join("");
    }

    if (parts.length === 2 && parts[1].length > 2) {
      sanitizedValue = `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    setProductData((prevData) => ({
      ...prevData,
      mrp: sanitizedValue,
    }));

    const basePriceValue = parseFloat(isProductData.basePrice);
    const mrpValue = parseFloat(sanitizedValue) || 0;

    if (mrpValue <= basePriceValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "*MRP must be greater than Sale price",
        basePrice: "",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        mrp: "",
      }));
    }

    if (basePriceValue >= mrpValue) {
      setError((prevErrors) => ({
        ...prevErrors,
        basePrice: "*Base price must be less than MRP",
      }));
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        basePrice: "",
      }));
    }
  };

  return (
    <div>
      <div className="overlay toggle-icon bc overlay1"></div>
      <div
        className="modal show"
        id="customerModal"
        aria-modal="true"
        role="dialog"
        onClick={onClose}
        style={{ display: "block" }}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-scrollable"
          onClick={stopEventPropagation}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="modal-content border-0">
            <div className="modal-header">
              <h6 className="modal-title">Edit Product</h6>
              <button
                type="button"
                onClick={onClose}
                className="btn-close"
              ></button>
            </div>
            <div className="modal-body">
              <form id="customerForm">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <label className="form-label">
                      Title <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                       autoComplete="off"
                      value={isProductData.title}
                      onChange={handleChange}
                      ref={productTitleRef}
                    />
                    {errors.title && (
                      <div className="error-message invalid-feedback">
                        {errors.title}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">
                      Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                       autoComplete="off"
                      value={isProductData.code}
                      onChange={handleChange}
                      ref={productCodeRef}
                    />
                    {errors.code && (
                      <div className="error-message invalid-feedback">
                        {errors.code}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-4">
                  <label htmlFor="FisrtName" className="form-label">
              Tax(%) <span className="text-danger">*</span>
            </label>
            <select
              name="taxpercentage"
              className="form-control"
              value={isProductData.taxpercentage}
              ref={productTaxRef}
              onChange={handleTaxPercentageChange}
             
            >
              <option value="0">0</option>
              <option value="5">5</option>
              <option value="12">12</option>
              <option value="18">18</option>
              <option value="24">24</option>
            </select>
          </div>
                  <div className="col-lg-4">
                    <label className="form-label">
                      MRP <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="mrp"
                       autoComplete="off"
                      placeholder="₹0"
                      value={isProductData.mrp}
                      onChange={handleMrpChange}
                      ref={productMrpRef}
                    />
                    {errors.mrp && (
                      <div className="error-message invalid-feedback">
                        {errors.mrp}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-4">
                    <label className="form-label">
                   Base Price <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="baseprice"
                       autoComplete="off"
                      placeholder="₹0"
                      value={isProductData.basePrice}
                      onChange={handleBasePriceChange}
                      ref={productbasepriceRef}
                    />
                    {errors.basePrice && (
                      <div className="error-message invalid-feedback">
                        {errors.basePrice}
                      </div>
                    )}
                  </div>

                  <div className="col-lg-4">
                    <label className="form-label">
                   Sale Price <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="saleprice"
                       autoComplete="off"
                      placeholder="₹0"
                      value={isProductData.saleprice}
                      disabled
                    />
                    
                  </div>
                  <div className="col-lg-4">
                    <label className="form-label">
                   Tax Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="taxamount"
                       autoComplete="off"
                      placeholder="₹0"
                      value={isProductData.taxAmount}
                      disabled
                    />
                   
                  </div>
             
                </div>
              </form>
            </div>
            <div className="modal-footer d-block">

            <CancelButton
                              buttonText="Cancel"
                              buttonIcon="bx bx-x"
                              onClick={onClose}
                              additionalClasses="btn-outline-secondary text-left"
                            />
                  {buttonloading ? (
                    <ButtonWithProgress label="  Updating..."
                    iconClass="bx bx-check"
                    buttonClass="btn btn-primary pull-right"
               
                    />
                     
                   
                  ) : (
                    <AddButton label="Update"
                      iconClass="bx bx-check"
                      buttonClass="btn btn-primary pull-right"
                      onClick={handleUpdate}/>
                     
                   
                  )}
              </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
