const SuccessMessages = {
ALLERY_IMAGE_DELETED:"Product gallery image has been deleted successfully.",

     // Agent Success
     AGENT_ADDED: "The user has been added successfully.",
     AGENT_UPDATED: " The user has been updated successfully.",
     AGENT_DELETED: " The user has been deleted successfully.",
     
    // Shop Success
   SHOP_ADDED: "The shop has been added successfully.",
   SHOP_UPDATED: " The shop has been updated successfully.",
   SHOP_DELETED: " The shop has been deleted successfully.",
   SHOP_STATUS_UPDATED:"Shop status has been updated successfully.",
  

  // Authentication Success
  LOGIN_SUCCESS_MSG:"You have successfully logged in to your account",
  LOGIN_SUCCESS: "Login successful. Welcome back!",
  LOGOUT_SUCCESS: "You have successfully logged out.",
  PASSWORD_RESET_SUCCESS:
    "Password reset successful. You can now log in with the new password.",

  // Email Success
  EMAIL_SENT: "Email has been sent successfully.",
  OTP_SENT: "OTP sent successfully. Please check your email.",
  EMAIL_VERIFIED: "Your email has been verified successfully.",
  INVITE_SUCCESS: "Your invitation accepted and account created successfully.",

  // OTP Success
  OTP_VERIFIED: "OTP verified successfully.",

  // File Upload Success
  FILE_UPLOADED: "File uploaded successfully.",

  // User Account Success
  ACCOUNT_CREATED: "Your account has been created successfully.",
  ACCOUNT_UPDATED: "Your account details have been updated successfully.",
  ACCOUNT_DELETED: "Your account has been deleted successfully.",

  // Product Success
  PRODUCT_ADDED: "Product added successfully.",
  PRODUCT_UPDATED: "Product updated successfully.",
  PRODUCT_DELETED: "Product deleted successfully.",
  PRODUCT_STOCK_UPDATED:"The product stock has been updated successfully.",
 
 // General Success
 OPERATION_SUCCESS: "The operation was successful.",
 DATA_FETCH_SUCCESS: "Data fetched successfully.",
 DATA_SAVE_SUCCESS: "Data saved successfully.",
 CONNECTION_RESTORED: "You are back online. Connection restored.",
  // Cart Success
  ITEM_ADDED_TO_CART: "Item added to your cart.",
  ITEM_REMOVED_FROM_CART: "Item removed from your cart.",
  CART_UPDATED: "Your cart has been updated.",

  // Order Success
  ORDER_UPDATED: "Order status has been updated successfully.",
  ORDER_PLACED: "Your order has been placed successfully.",
  ORDER_CANCELLED: "Your order has been cancelled successfully.",
  ORDER_RETURN_INITIATED: "Return request initiated successfully.",
  ORDER_DELIVERED: "Your order has been delivered successfully.",

  
  // Shipping Success
  SHIPPING_ADDRESS_ADDED: "Shipping address added successfully.",
  SHIPPING_ADDRESS_UPDATED: "Shipping address updated successfully.",
  SHIPPING_ADDRESS_DELETED: "Shipping address deleted successfully.",


  // Notification Success
  NOTIFICATION_SUBSCRIBED: "You have successfully subscribed to notifications.",
  NOTIFICATION_UNSUBSCRIBED:
    "You have successfully unsubscribed from notifications.",

  // order
  TRANKINGID_EXIST: "Tracking ID already exists.",

ORDER_UPDATED_FAILED:"Failed to update order status.",
  // Custom Success
  CUSTOM_SUCCESS: "Operation completed successfully.",

  FORM_SUBMITTED: "Form submitted successfully.",
};

export default SuccessMessages;
