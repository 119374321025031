import React, { useContext } from "react";
import { Snackbar, Alert } from "@mui/material";
import { SnackbarContext } from "../../../contexts/SnackbarContext";


const SnackbarComponent = () => {
  const { snackbar, closeSnackbar } = useContext(SnackbarContext);
  const validSeverities = ["error", "info", "success", "warning"];
  const severity = validSeverities.includes(snackbar.severity) ? snackbar.severity : "info";
  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={3000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={closeSnackbar} severity={severity} variant="filled">
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;