import axios from "axios";
const api_url = process.env.REACT_APP_BASE_URL_ALAGAPPA_AGENCIES;

const axiosInstance = axios.create({
  baseURL: api_url,
  headers: {
    "Content-Type": "application/json",
  
  },
});
export const authAccount = async (payload, handleError) => {
  try {
    const response = await axiosInstance.post("auth-account", payload);
    return response.data.results;
  } catch (error) {
    if (error.response) {
      handleError(error.response);
    }
    throw error;
  }
};
