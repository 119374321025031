import React, { useEffect, useState } from "react";
import "../../../style/order.css";

import SuccessMsg from '../../common/messages/SuccessMessage'
import useShowAlert from "../../../utils/AlertUtiles";
import useErrorHandle from "../../../hooks/ErrorHandling";
import checkNetworkStatus from "../../common/networkStatus/CheckNetworkStatus";
import { updateOrderStatus } from "../../../services/orderServices";
import WarningErrorHandling from "../../../hooks/WarningErrorHandling";
function ViewOrders({ order, onClose, setOrders ,authToken}) {
   const [IsStatus, setStatus] = useState("CREATED");
   const [isStateLoading, setStateloading] = useState(false);
 
   const showAlert = useShowAlert();
  useEffect(() => {
    if (order) {
      setStatus(order.status);
    
    }
  }, [order]);

  const handleError = useErrorHandle();


  const getColor = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "bg-label-success1";
      case "created":
        return "bg-label-info";
      case "accepted":
        return "bg-label-blue";
      default:
        return "bg-label-default";
    }
  };
  const formatOrderDate = (dateString) => {
    const date = new Date(dateString);

    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(" ", "-")
      .replace(" ", "-")
      .toUpperCase();
  };

  
  const handleStatusUpdate = async (event) => {
    checkNetworkStatus();
    setStateloading(true);
    const newStatus = event.target.value;
    setStatus(newStatus);
    try {
      const payload = {
        shopid: order.shopid._id,
        orderid: order._id,
        status: newStatus,
      };

      const data = await updateOrderStatus(payload, handleError,authToken);
      if (data) {
        setStatus(data.status);;
        setOrders((prevData) =>
          prevData.filter((ord) => ord._id !== order._id)
        );
        showAlert("success", SuccessMsg.ORDER_UPDATED);
      }
    } catch (error) {
      const statusCode = error?.response?.status || error?.statusCode;

      if (statusCode !== 404) {
        WarningErrorHandling(error, showAlert);
      } else if (statusCode === 404) {
        setStatus([]);
      }
    } finally {
      setStateloading(false);
    }
  };





  return (
    <div>
      <div className="card">
        <div>
          <div className="modal-content">
            <div
              className="modal-header p-3"
              style={{ borderBottom: "2px solid #e9ecff" }}
            >
              <h6 className="modal-title">View Order</h6>
              <button
                type="button"
                onClick={onClose}
                className="btn-close fs-16"
              ></button>
            </div>
            {order ? (
              <div className="modal-body mb-2 mheight">
                <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                  <div className="box-header bg-light-theme px-2 py-1">
                    <h6 className="modal-title bgtxt text-primary">
                      <small> Order Info</small>
                    </h6>
                  </div>
                  <div className="card-body p-1 box-border">
                    <table className="table table-borderless table-sm mb-0">
                      <tbody>
                        <tr>
                          <td className="text-muted">Order Id</td>
                          <td>{order._id}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">Date</td>
                          <td> {formatOrderDate(order.orderdate)}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">Status</td>
                          <td className="text-muted">
                            <td className="text-muted">
                              <span
                                className={`badge rounded-pill ${getColor(
                                  IsStatus
                                )}`}
                              >
                                {IsStatus || ""}
                              </span>
                            </td>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                  <div className="box-header bg-light-theme px-2 py-1">
                    <h6 className="modal-title bgtxt text-primary">
                      <small>Order Details</small>
                    </h6>
                  </div>
                  <div className="card-body p-1 box-border">
                    <table className="table table-bordered align-items-center mb-0">
                      <thead>
                        <tr className="">
                          <th className="text-left text-dark bg-light">Title</th>
                          <th className="text-center text-dark bg-light">Qty</th>
                          <th className="text-center text-dark bg-light">Price</th>

                          <th className="text-center text-dark bg-light">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.cart_data && order.cart_data.length > 0 ? (
                          order.cart_data.map((item, index) => (
                            <tr key={item._id || index}>
                              <td className="text-left text-muted">{item.title}</td>
                              <td className="text-center text-muted">
                                {item.quantity}
                              </td>
                              <td className="text-center text-muted">
                                {item.saleprice}
                              </td>

                              <td className="text-center text-muted">
                                {(item.quantity * item.saleprice || 0).toFixed(
                                  2
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <p className="text-center">Your cart is empty.</p>
                        )}
                        <tr>
                          <td className="text-right" colSpan="3">
                            <h6 className="my-1">Grand Total</h6>
                          </td>
                          <td className=" bg-light">
                            <h5 className="mb-0 fw-600">
                              ₹{order.grandtotal.toFixed(2)}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                  <div className="box-header bg-light-theme px-2 py-1">
                    <h6 className="modal-title bgtxt text-primary">
                      <small>Update Order Status</small>
                    </h6>
                  </div>
                  <div className="card-body p-2 box-border">
                  <div className="row g-1">
  {isStateLoading ? (
    <div className="col text-center">
      
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    ["CREATED", "ACCEPTED", "COMPLETED", "CANCELLED"].map((status) => {
      const statusClasses = {
        CREATED: "btn-info",
        ACCEPTED: "btn-primary",
        COMPLETED: "btn-success",
        CANCELLED: "btn-danger",
      };

      return (
        <div className="col" key={status}>
          <button
            type="button"
            className={`btn btn-sm rounded-2 ${
              IsStatus === status ? statusClasses[status] : "btn-light"
            }`}
            value={status}
            onClick={handleStatusUpdate}
          >
            {status.charAt(0) + status.slice(1).toLowerCase()}
          </button>
        </div>
      );
    })
  )}
</div>

                  </div>
                </div>

                {order?.shopid?.address &&  (
                  <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                    <div className="box-header bg-light-theme px-2 py-1">
                      <h6 className="modal-title bgtxt text-primary">
                        <small>Shop Address</small>
                      </h6>
                    </div>
                    <div className="card-body p-1 box-border">
                      <table className="table table-borderless table-sm mb-0">
                        <tbody>
                          <tr>
                            <td className="text-muted"> Name:</td>
                            <td>{order.shopid.name}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">Address:</td>
                            <td>
                            {order.shopid.address.addressline1 &&
                  `${order.shopid.address.addressline1}, `}
                {order.shopid.address.addressline2 &&
                  `${order.shopid.address.addressline2}, `}
                {order.shopid.address.city && `${order.shopid.address.city}, `}
                {order.shopid.address.state &&
                  `${order.shopid.address.state} - `}
                {order.shopid.address.pincode}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {order?.shopid?.billing_address &&(
                  <div className="card shadow-none px-1 py-0 mb-0 mt-2">
                    <div className="box-header bg-light-theme px-2 py-1">
                      <h6 className="modal-title bgtxt text-primary">
                        <small> Biling Address</small>
                      </h6>
                    </div>
                    <div className="card-body p-1 box-border">
                      <table className="table table-borderless table-sm mb-0">
                        <tbody>
                          <td>
                          {order.shopid.billing_address.addressline1 &&
                  `${order.shopid.billing_address.addressline1}, `}
                {order.shopid.billing_address.addressline2 &&
                  `${order.shopid.billing_address.addressline2}, `}
                {order.shopid.billing_address.city &&
                  `${order.shopid.billing_address.city}, `}
                {order.shopid.billing_address.state &&
                  `${order.shopid.billing_address.state} - `}
                {order.shopid.billing_address.pincode}
                          </td>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center p-2">
                Select order to view deatails
              </div>
            )}
          </div>
        </div>
      </div>
   
    </div>
  );
}

export default ViewOrders;
