import React, { useEffect, useState } from "react";

import SuccessMsg from "../../common/messages/SuccessMessage";
import useErrorHandle from "../../../hooks/ErrorHandling";
import useShowAlert from "../../../utils/AlertUtiles";
import checkNetworkStatus from "../../common/networkStatus/CheckNetworkStatus";
import { updateShopStatus } from "../../../services/shopServices";
import WarningErrorHandling from "../../../hooks/WarningErrorHandling";
import AddButton from "../../reusable/Buttons/AddButton";
import TableHeader from "../../common/table/TableHeader";
import TableSkeleton from "../../common/table/TableSkeleton";
import EditButton from "../../reusable/EditIcon";
import DeleteButton from "../../reusable/DeleteIcon";
import DeleteDialog from "../../reusable/DeleteDialog";
import EditShopDialog from "./EditShopModel";
import { Checkbox } from "@mui/material";

import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
const ShopTable = ({
  data = [],
  handleUpdate,
  loading,openModal,
  updateload,
  errorMessage,
  handleDelete,authToken
}) => {
  const [selectedShop, setSelectedShop] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [shopData, setShopData] = useState(data);

  const handleError = useErrorHandle();
  const showAlert = useShowAlert();
  const headers = [
    
    "S.No",
    "Shop Code",
    "Shop Name",
    "Phone Number",
    "Reg No",
    "State",
     "Status",
    "Action"
    
  ];

  const filteredData = shopData.filter((shop) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (shop.name && shop.name.toLowerCase().includes(searchLower)) ||
      (shop.phone && shop.phone.toLowerCase().includes(searchLower)) ||
      (shop.registerno && shop.registerno.toLowerCase().includes(searchTerm)) ||
      (shop.address.state && shop.address.state.toLowerCase().includes(searchTerm)) ||
      (shop.shopcode && shop.shopcode.toLowerCase().includes(searchTerm)) ||
      (shop.status && shop.status.toLowerCase().includes(searchTerm))
    );
  });
  useEffect(() => {
    if (data.length > 0) {
      setShopData(data);
    }
  }, [data]);
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedShop(null);
  };

  const openEditModal = (shop) => {
    setSelectedShop(shop);
    setIsDialogOpen(true);
  };

  const updateShop = (updatedShopData) => {
    handleUpdate(updatedShopData);
    handleCloseDialog();
  };
  const confirmDelete = () => {
    handleDelete(selectedShop._id);
    setIsDeleteDialogOpen(false);
  };
  const openDeleteDialog = (shop) => {
    setSelectedShop(shop);
    setIsDeleteDialogOpen(true);
  };


  const handleToggleStatusChange = async (shopId, currentStatus) => {
    checkNetworkStatus();
    try {
      const newStatus = currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";
      const payload = { shopid: shopId, status: newStatus };
  
      const response = await updateShopStatus(payload, handleError,authToken);
  
      if (response) {
        setShopData((prevData) =>
          prevData.map((shop) =>
            shop._id === shopId ? { ...shop, status: newStatus } : shop
          )
        );
        showAlert("success", SuccessMsg.SHOP_STATUS_UPDATED);
      } 
    } catch (error) {
      WarningErrorHandling(error, showAlert);}
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
           
          <div className="card-header m-1">
          
              <div className="d-flex align-items-center justify-content-between gap-2">
            
              <div className="col-sm-8">
                <div className="input-group ">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search shop..."
                    fdprocessedid="vlw39b"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className="input-group-text"
                    fdprocessedid="1vtzoc"
                    style={{ backgroundColor: "#ededff" }}
                  >
                    <i className="bx bx-search"></i>
                  </button>
                </div>
              </div>
              <div className="col-sm-4  d-flex justify-content-end ">
              <div className="btn-group">

<AddButton label="Add Shop"
  iconClass="bx bx-plus"
  buttonClass="btn btn-primary text-right"
  onClick={openModal}/>
    


</div>
              </div>
            </div>
            </div>
            <div className="card-body p-0">
              <div
                className="table-responsive"
                style={{
                  flex: "1",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 200px)",
                }}
              >
                <table className="table align-middle mb-0">
                <TableHeader headers={headers} />
                  <tbody>
                    {loading ? (
                       <TableSkeleton
                       rows={5}
                       columns={[40, 200, 400, 100, 100, 100,100,  200]}
                     />
                     
                    ) : Array.isArray(filteredData) && filteredData.length > 0 ? (
                      filteredData.map((shop, index) => (
                        <tr key={`${shop.id}-${index}`}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">
                            <span
                              style={{
                                color: "#007cf3",
                                backgroundColor: "rgb(13 110 253 / .11)",
                              }}
                              className="badge rounded-pill"
                            >
                              {shop.shopcode}
                            </span>
                          </td>
                          <td className="text-center">
                          {shop.name.charAt(0).toUpperCase() + shop.name.slice(1)}
                          </td>
                          <td className="text-center">{shop.phone || "-"}</td>
                          <td className="text-center">{shop.registerno || "-"}</td>
                          <td className="text-center">
                            {shop.address?.state || "-"}
                          </td>
                          <td className="text-center p-0" >
                          <Checkbox
                            checked={shop.status === "ACTIVE"}
                            onChange={() => handleToggleStatusChange(shop._id, shop.status)}
                            icon={<ToggleOffIcon sx={{ fontSize: "40px",color:'gray' }}/>}
                            checkedIcon={<ToggleOnIcon sx={{ fontSize: "40px",color:'green' }} />}
                          />
        
        {/* <ToggleUI

checked={shop.status === "ACTIVE"}
onChange={(e) => handleStatusChange(shop._id, shop)}
/> */}

</td>
                          <td>
                            <div className="d-block order-actions text-center">
                              <EditButton onEdit={() => openEditModal(shop)} />
                              <DeleteButton onDelete={() => openDeleteDialog(shop)}/>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          There are no shops available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <EditShopDialog
          shop={selectedShop}
          onClose={handleCloseDialog}
          updateShop={updateShop}
          updateload={updateload}
          errorMessage={errorMessage}
        />
      )}

      {isDeleteDialogOpen && (
        <DeleteDialog
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
          Name="shop"
        />
      )}
    </>
  );
};

export default ShopTable;
