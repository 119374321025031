import React, { useEffect, useState } from "react";
import EditButton from "../../reusable/EditIcon";
import DeleteButton from "../../reusable/DeleteIcon";
import EditProduct from "./EditProduct";
import DeletePopup from "../../common/dialog/DeletePopup";
import AddButton from "../../reusable/Buttons/AddButton";
import TableHeader from "../../common/table/TableHeader";
import TableSkeleton from "../../common/table/TableSkeleton";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import checkNetworkStatus from "../../common/networkStatus/CheckNetworkStatus";
import { updateStoke } from "../../../services/productServices";
import WarningErrorHandling from "../../../hooks/WarningErrorHandling";

import useShowAlert from "../../../utils/AlertUtiles";
import SuccessMsg from "../../common/messages/SuccessMessage";
import useErrorHandle from "../../../hooks/ErrorHandling";
import { Checkbox } from "@mui/material";
const formatNumber = (value) => {
  if (isNaN(value) || value === "") return "0.00";

  const parts = value.toString().split(".");

  if (parts.length === 2 && parts[1].length === 1) {
    parts[1] = "0" + parts[1];
  } else if (parts.length === 1) {
    parts.push("00");
  }

  return parts.join(".");
};

const ProductTable = ({
  data = [],
  handleUpdate,
  handleDelete,
  loading,
  openModal,
  buttonloading,authToken
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isProductData, setProduct] = useState(data);
  const [searchTerm, setSearchTerm] = useState("");
  const handleError = useErrorHandle();
  const showAlert = useShowAlert();

   useEffect(() => {
      if (data.length > 0) {
        setProduct(data);
      }
    }, [data]);
  const filteredData = isProductData.filter((product) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      product.code.toLowerCase().includes(searchLower) ||
      product.title.toLowerCase().includes(searchLower) ||
      product.mrp.toString().includes(searchTerm) ||
      product.saleprice.toString().includes(searchTerm)
    );
  });
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedProduct(null);
  };

  const openEditModal = (product) => {
    setSelectedProduct(product);
    setIsDialogOpen(true);
  };
  const openDeleteDialog = (product) => {
    setSelectedProduct(product);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = () => {
    handleDelete(selectedProduct._id);
    setIsDeleteDialogOpen(false);
  };
  const UpdateProduct = (updatedProductData) => {
    handleUpdate(updatedProductData);
    handleCloseDialog();
  };
  const headers = [
    "S.No",
    "Product Code",
    "Product Name",
    "MRP",
    "Base Price",
    "Sale Price",
    "Tax",
    "Tax Amount",
    "Stock",
    "Action",
  ];


  const handleToggleStockChange = async (productId, currentStatus) => {
    checkNetworkStatus();
     try {
      const newStock = currentStatus === true ? false : true;
      const payload = { productid: productId, isStock: newStock };
  
    
      const response = await updateStoke(payload, handleError,authToken);
  
      if (response) {
        setProduct((prevData) =>
          prevData.map((product) =>
            product._id === productId ? { ...product, isStock: newStock } : product
          )
        );
        showAlert("success", SuccessMsg.PRODUCT_STOCK_UPDATED);
      } 
    } catch (error) {
      WarningErrorHandling(error, showAlert);}
  };
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header m-1">
              <div className="d-flex align-items-center justify-content-between gap-2">
                <div className="col-sm-8">
                  <div className="input-group ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search product..."
                      fdprocessedid="vlw39b"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button
                      className="input-group-text"
                      fdprocessedid="1vtzoc"
                      style={{ backgroundColor: "#ededff" }}
                    >
                      <i className="bx bx-search"></i>
                    </button>
                  </div>
                </div>
                <div className="col-sm-4 d-flex justify-content-end ">
                  <div className="btn-group">
                    <AddButton
                      label=" Add Product"
                      iconClass="bx bx-plus"
                      buttonClass="btn btn-primary text-right"
                      onClick={openModal}
                    />
                  </div>
                </div>
              </div>{" "}
            </div>

            <div className="card-body p-0">
              <div
                className="table-responsive"
                style={{
                  flex: "1",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 200px)",
                }}
              >
                <table className="table align-middle mb-0">
                  <TableHeader headers={headers} />
                  <tbody>
                    {loading ? (
                      <TableSkeleton
                        rows={5}
                        columns={[
                          40, 100, 200, 100, 100, 100, 100, 200, 100, 200,
                        ]}
                      />
                    ) : Array.isArray(filteredData) &&
                      filteredData.length > 0 ? (
                      filteredData.map((product, index) => (
                        <tr key={`${product.productid}-${index}`}>
                          <td className="text-center">{index + 1}</td>

                          <td className="text-center">
                            <span
                              style={{
                                color: "#007cf3 ",
                                backgroundColor: "rgb(13 110 253 / .11) ",
                              }}
                              className="badge rounded-pill "
                            >
                              {product.code}
                            </span>
                          </td>
                          <td className="text-center">
                            <h6 className="mb-0 fw-500">
                              {product.title.charAt(0).toUpperCase() +
                                product.title.slice(1)}
                            </h6>
                          </td>

                          <td className="text-center ">
                            ₹{formatNumber(product.mrp)}
                          </td>
                          <td className="text-center ">
                            ₹{formatNumber(product.baseprice)}
                          </td>
                          <td className="text-center ">   ₹{formatNumber(product.saleprice)}</td>
                          <td className="text-center">
                            <span className="badge bg-light-purple">
                              {" "}
                              {product.taxpercentage || 0}%
                            </span>
                          </td>
                          <td className="text-center ">   ₹{formatNumber(product.taxamount|| 0.0)}</td>
                          <td className="text-center p-0">
                          <Checkbox
                           checked={product.isStock === true || product.isStock === undefined}
                            onChange={() => handleToggleStockChange(product._id, product.isStock)}
                            icon={<ToggleOffIcon sx={{ fontSize: "40px",color:'gray' }}/>}
                            checkedIcon={<ToggleOnIcon sx={{ fontSize: "40px",color:'green' }} />}
                          />
                            
                          </td>
                          <td>
                            <div className="d-block order-actions text-center">
                              <EditButton
                                onEdit={() => openEditModal(product)}
                              />
                              <DeleteButton
                                onDelete={() => openDeleteDialog(product)}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No Products available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDialogOpen && (
        <EditProduct
          productData={selectedProduct}
          onClose={handleCloseDialog}
          updateProduct={UpdateProduct}
          buttonloading={buttonloading}
        />
      )}

      {isDeleteDialogOpen && (
        <DeletePopup
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={confirmDelete}
          Name="product"
          buttonloading={buttonloading}
        />
      )}
    </>
  );
};

export default ProductTable;
