const ErrorMessages = {
    // Authentication Errors
    TOKEN_MISSING: "Authentication token is missing. login again and continue.",
    TOKEN_INVALID: "Invalid token. Authentication failed.",
    TOKEN_EXPIRED: "Your session has expired. Please log in again.",
    
    // General Errors
    NO_INTERNET: "You are offline. check your internet connection.",
    UNKNOWN_ERROR: "An unexpected error occurred. Please try again.",
    SERVICE_UNAVAILABLE: "Service is currently unavailable. Please try again later.",
    UNAUTHORIZED_ACCESS: "Unauthorized access. Please log in again.",
    FORBIDDEN: "You do not have permission to access this resource.",
    NOT_FOUND: "The requested resource was not found.",
    TIMEOUT: "The request timed out. Please try again.",
    SERVER_ERROR: "An internal server error occurred.",
    URL_NOT_FOUND: "The requested endpoint was not found.",
    INVALID_RESPONSE_FORMAT: "The server returned an unexpected response. Please contact support.",
    SERVER_DOWN: "Connection to the server was refused.",
    
    // Email Errors
    EMPTY_EMAIL: "Email cannot be empty.",
    INVALID_EMAIL: "Enter a valid email address.",
    EMAIL_EXISTS: "This email is already in use.",
    EMAIL_NOT_FOUND: "This email is not registered.",
    
    // Password Errors
    EMPTY_PASSWORD: "Password cannot be empty.",
    INVALID_PASSWORD: "Password must be at least 8 characters long and include a mix of letters, numbers, and special characters.",
    PASSWORD_MISMATCH: "Passwords do not match.",
    PASSWORD_RESET_FAIL: "Failed to reset password. Try again.",
    
    // OTP Errors
    OTP_EMPTY: "OTP cannot be empty.",
    OTP_INVALID: "Enter a valid 6-digit OTP.",
    OTP_SEND_FAIL: "Failed to send verification OTP. Retry again.",
    OTP_VERIFY_FAIL: "Failed to verify OTP. Retry again.",
    OTP_VERIFY_ERROR: "An error occurred during OTP verification..",
    
    // Form Validation Errors
    FIELD_REQUIRED: "This field is required.",
    INVALID_INPUT: "The input provided is invalid.",
    TOO_SHORT: "Input is too short.",
    TOO_LONG: "Input is too long.",
    
    // User Errors
    USER_NOT_FOUND: "User not found.",
    USER_EXISTS: "User already exists.",
    ACCOUNT_LOCKED: "Your account has been locked. Contact support for assistance.",
    ACCOUNT_INACTIVE: "Your account is inactive. Please verify your email or contact support.",
    
    // Network/Request Errors
    BAD_REQUEST: "The request was invalid or cannot be processed.",
    CONFLICT: "There is a conflict with the current state of the resource.",
    PAYLOAD_TOO_LARGE: "The request payload is too large.",
    
    // File Upload Errors
    FILE_TOO_LARGE: "The file is too large. Maximum allowed size is 5MB.",
    UNSUPPORTED_FILE_TYPE: "The file type is not supported.",
    UPLOAD_FAIL: "File upload failed. Try again.",
    
    // Authentication Errors
    LOGIN_FAIL: "Login failed. Check your credentials and try again.",
    SESSION_EXPIRED: "Your session has expired. Please log in again.",
    
    // API Errors
    API_CALL_FAIL: "Failed to communicate with the server. Try again later.",
    DATA_FETCH_FAIL: "Failed to fetch data. Try again.",
    DATA_SAVE_FAIL: "Failed to save data. Try again.",
  
    // Phone Errors
    EMPTY_PHONE: "Phone cannot be empty.",
    INVALID_PHONE: "Enter a valid 10-digit phone numebr.",
    INVALID_PINCODE: "Enter a valid 6-digit pincode.",
    INVALID_GST_NUMBER: "Invalid GST number format.",
    INVALID_PAN_NUMBER: "Invalid PAN number format.",
    INVALID_CIN_NUMBER: "Invalid CIN number format.",
  
    // Product 
    CODE_EMPTY: "Code cannot be empty.",
    TITLE_EMPTY: "Title cannot be empty.",
    MRP_EMPTY: "MRP cannot be empty.",
    SALEPRICE_EMPTY: "Saleprice cannot be empty.",
    TAX_EMPTY: "Tax percentage cannot be empty.",
    BASEPRICE_EMPTY: "Baseprice cannot be empty.",
    TAXAMOUNT_EMPTY: "Taxamount cannot be empty.",
    DISCOUNT_EMPTY: "Discount cannot be empty.",
  
    INVALID_PAID_AMOUNT: "Paid amount must be equal to total payabale.",
    
  
    // Category 
    NOT_SELECT_CATEGORY:"Please selct the MainCategory name",
    NOT_SELECT_SUBCATEGORY:"Please selct the SubCategory name",
    
    // Custom Errors
    CUSTOM_ERROR: "A custom error occurred.",
  
    EMPTY_STORE: "Substore cannot be empty.",
  };
  
  export default ErrorMessages;